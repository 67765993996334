/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export const preventsFlashing = (
  className: string,
  duration?: number,
  display?: string,
) => {
  // Prevents flashing
  gsap.to(className, {
    duration: duration || 0.1,
    css: { visibility: 'visible', display: display || 'flex' },
  });
};

export const showTextAnimation = (ref: Array<string>, delay?: any, y?: any) => {
  gsap.to('body', {
    duration: 0,
    css: { visibility: 'visible' },
  });

  gsap.from([ref], {
    duration: 0.8,
    ease: 'power3.out',
    y: y || 100,
    delay: delay || 0,
    stagger: {
      amount: 0.1,
    },
  });
};

export const onEnterUnderlineWordAnimation = (ref: any) => {
  const tl = gsap.timeline();
  tl.fromTo(
    [ref, '.underline'],
    {
      width: '0%',
      left: '0%',
    },
    {
      width: '100%',
      duration: 0.2,
    },
  );
};

export const onLeaveUnderlineWordAnimation = (ref: any) => {
  const tl = gsap.timeline();
  tl.fromTo(
    [ref, '.underline'],
    {
      width: '100%',
      left: '0%',
      duration: 0.5,
    },
    {
      width: '0%',
      left: '100%',
      duration: 0.5,
      immediateRender: false,
    },
  );
};

export const onEnterYAnimation = (ref: any) => {
  const tl = gsap.timeline();
  tl.fromTo(
    [ref],
    {
      y: -200,
    },
    {
      duration: 0.7,
      y: 0,
    },
  );
};

export const onLeaveYAnimation = (ref: any) => {
  const tl = gsap.timeline();
  tl.fromTo(
    [ref],
    {
      y: 0,
      duration: 1,
    },
    {
      y: -80,
    },
  );
};

export const fadeIn = (element: string) => {
  gsap.to(element, {
    delay: 0.1,
    duration: 0.8,
    opacity: 1,
    y: 0,
    ease: 'power4.out',
    stagger: {
      amount: 0.3,
    },
  });
};

export const fadeOut = (element: string) => {
  gsap.to(element, {
    delay: 0.1,
    duration: 0.8,
    y: 25,
    opacity: 0,
    ease: 'power4.out',
  });
};

// PLAYGROUND SCROLL ANIMATION

export const onScrollImagesAnimation = (
  containerClass: string,
  triggerClass: string,
) => {
  const images = gsap.utils.toArray(containerClass);
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: triggerClass,
      start: 'top 100%',
      end: 'bottom bottom',
      toggleActions: 'play none none reverse',
      scrub: true,
    },
  });

  images.forEach((el: any) => {
    tl.from(el, {
      yPercent: 100,
      opacity: 0.5,
      ease: 'power2.out',
    }).to(el, {
      opacity: 1,
      yPercent: 0,
      ease: 'power2.out',
      paused: true,
      stagger: { each: 2 },
      autoAlpha: 1,
    });
  });
};

export const handleRemovePage = (
  classes: string[],
  delay: number,
  duration: number,
) => {
  const tl = gsap.timeline();
  tl.to(classes, {
    delay,
    duration,
    opacity: 0,
  });
};

export const handleTransformToGrayscale = (el: string) => {
  gsap.to(el, {
    duration: 1,
    css: { filter: 'grayscale(0%)' },
    delay: 0.3,
    ease: 'power1.out',
  });
};

export const handleRevertTransformToGrayscale = (el: string) => {
  gsap.to(el, {
    duration: 1,
    css: { filter: 'grayscale(100%)' },
    delay: 0.3,
    ease: 'power1.out',
  });
};
