import './BlockText.scss';
import OverflowWrapper from '../OverflowWrapper/OverflowWrapper';

interface IProps {
  title: string;
  options: Array<{ url: string; text: string }>;
}

function BlockText(props: IProps): JSX.Element {
  const { title, options } = props;

  return (
    <div className="block-text__info--content">
      <OverflowWrapper height="fit-content">
        <p className="block-text__info--title">{title}</p>
      </OverflowWrapper>
      <span>
        {options.map((option) => {
          return (
            <OverflowWrapper key={option.text} height="fit-content">
              <a
                className="block-text__info--text"
                href={option.url}
                target="_blank"
                rel="noreferrer"
              >
                {option.text}
              </a>
            </OverflowWrapper>
          );
        })}
      </span>
    </div>
  );
}

export default BlockText;
