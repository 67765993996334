import './Photo.scss';

interface IProps {
  src: string;
  alt: string;
  onLoad?: () => void;
  customStyle?: string;
}

function Photo(props: IProps): JSX.Element {
  const { src, alt, onLoad, customStyle } = props;

  return <img className={customStyle} onLoad={onLoad} src={src} alt={alt} />;
}

export default Photo;
