/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useState } from 'react';

export const MouseContext = createContext({
  cursorType: '',
  cursorText: '',
  cursorChangeHandler: (_cursorType: any, _cursorText?: any) => {},
});

const MouseContextProvider = (props: any): any => {
  const [cursorType, setCursorType] = useState('');
  const [cursorText, setCursorText] = useState('');

  const cursorChangeHandler = (_cursorType: any, _cursorText?: any) => {
    setCursorType(_cursorType);
    setCursorText(_cursorText);
  };

  return (
    <MouseContext.Provider
      value={{
        cursorType,
        cursorText,
        cursorChangeHandler,
      }}
    >
      {props.children}
    </MouseContext.Provider>
  );
};

export default MouseContextProvider;
