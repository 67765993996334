import { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import useFontFaceObserver from 'use-font-face-observer';

import {
  HomePage,
  NotFoundPage,
  WorkPage,
  PlaygroundPage,
  ContactPage,
  PhotosPage,
} from './pages';
import { NavBar, Footer, CustomCursor } from './components/Basic';
import useMediaQuery from './hooks/useMediaQuery';

import './App.scss';

function App(): JSX.Element {
  const isPageWide = useMediaQuery('(min-width: 600px)');

  const isFontLoaded = useFontFaceObserver([
    { family: `RoobertTRIAL-Regular` },
  ]);
  const isSecondFontLoaded = useFontFaceObserver([
    { family: `RoxboroughCF-Regular` },
  ]);
  const location = useLocation();

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(
      'Hello!👋 I am delighted that you are inspecting🔎 my project. If you have any questions ❓, suggestions 🤔 or compliments ❤️, do not hesitate to contact me 📧: Marta Mullor (marta.mullor.polo@gmail.com)😊🚀',
    );
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      {isFontLoaded && isSecondFontLoaded && (
        <>
          <NavBar />
          {isPageWide && <CustomCursor />}
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/work" component={WorkPage} />
            <Route exact path="/labs-creativity" component={NotFoundPage} />
            <Route exact path="/services" component={NotFoundPage} />
            <Route exact path="/about" component={NotFoundPage} />
            <Route exact path="/contact" component={ContactPage} />
            <Route exact path="/photos" component={PhotosPage} />
            <Route exact path="/playground" component={PlaygroundPage} />
            <Route component={NotFoundPage} />
          </Switch>
          {isPageWide && <Footer />}
        </>
      )}
    </>
  );
}

export default App;
