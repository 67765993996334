import './NavBar.scss';
import { useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  preventsFlashing,
  showTextAnimation,
} from '../../../utils/animation-utils';
import { MouseContext } from '../../../Context/MouseContext';

interface IProps {
  text?: string | null;
}

function NavBar(props: IProps): JSX.Element {
  const { text } = props;
  const navBarTitle = useRef<any>(null);
  const navBarSubtitle = useRef<any>(null);
  const navBarLetters = useRef<any>(null);
  const { cursorChangeHandler } = useContext(MouseContext);

  useEffect(() => {
    preventsFlashing('.nav-bar');
    showTextAnimation([
      navBarTitle.current,
      navBarLetters.current,
      navBarSubtitle.current,
    ]);
  }, []);

  return (
    <>
      <nav className="nav-bar">
        <Link ref={navBarTitle} to="/" className="nav-bar__container">
          <span
            onMouseEnter={() => cursorChangeHandler('navBarLogo')}
            onMouseLeave={() => cursorChangeHandler('')}
            className="nav-bar__point"
          >
            .
          </span>
          <span className="nav-bar__message">
            Open for any collaborations and offers
          </span>
        </Link>
        {text && (
          <p ref={navBarLetters} className="nav-bar__letters">
            - {text}
          </p>
        )}
        <div className="nav-bar__buttons">
          {/* <ChangeLanguageBar /> */}
          <div ref={navBarSubtitle} className="nav-bar__title">
            Folio <br /> <span>Vol.1—</span>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
