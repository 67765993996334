import './HomePage.scss';
import { useEffect, useRef, useContext } from 'react';
import {
  showTextAnimation,
  preventsFlashing,
  handleRemovePage,
} from '../../utils/animation-utils';
import { OverflowWrapper } from '../../components/Basic';
import { Menu } from '../../components/Complex';
import { MouseContext } from '../../Context/MouseContext';

function HomePage(): JSX.Element {
  const homeTitle = useRef<any>(null);
  const textTitle = useRef<any>(null);
  const { cursorChangeHandler } = useContext(MouseContext);

  useEffect(() => {
    preventsFlashing('.home-page__content');
    showTextAnimation([homeTitle.current, textTitle.current], 0.2, 220);
  }, []);

  return (
    <div className="home-page__content">
      <OverflowWrapper height="fit-content">
        <p ref={homeTitle} className="home-page__title">
          <span className="home-page__title home-page__title--font">Hey! </span>
          Human
          <span className="home-page__title home-page__title--small">(ML)</span>
        </p>
      </OverflowWrapper>
      <Menu
        handleRemovePage={() =>
          handleRemovePage(['.home-page__title', '.home-page__text'], 0.1, 0.1)
        }
      />
      <OverflowWrapper height="fit-content">
        <p
          ref={textTitle}
          onMouseEnter={() => cursorChangeHandler('onHoverText')}
          onMouseLeave={() => cursorChangeHandler('')}
          className="home-page__text"
        >
          Marta Mullor (SHE/HER) is a Software Engineer and Digital Designer
          from Barcelona, Spain. Passionate about creating unforgettable and
          beautiful digital experiences.
        </p>
      </OverflowWrapper>
    </div>
  );
}

export default HomePage;
