/* eslint-disable @typescript-eslint/no-unused-vars */
import './Button.scss';

interface IProps {
  customStyle?: string;
  text: string;
  number?: string;
  onClick: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  width?: string;
  fontSize?: string;
  disabled?: boolean;
  isActive?: boolean;
}

function Button(props: IProps): JSX.Element {
  const {
    customStyle,
    number,
    text,
    fontSize,
    width,
    isActive,
    disabled,
    onClick,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const classNames = ['button'];
  if (isActive) classNames.push('button__active');
  if (disabled) classNames.push('button__disabled');
  if (customStyle) classNames.push(customStyle);

  return (
    <button
      type="button"
      className={classNames.join(' ')}
      onMouseEnter={onMouseEnter ? () => onMouseEnter() : () => ''}
      onMouseLeave={onMouseLeave ? () => onMouseLeave() : () => ''}
      onClick={() => onClick()}
      onKeyDown={() => onClick()}
      style={{ width, fontSize }}
      disabled={disabled}
    >
      {number && <p>{number}</p>}
      {text}
    </button>
  );
}

export default Button;
