import './ImageCard.scss';
import { useEffect, useRef } from 'react';
import { showTextAnimation } from '../../../utils/animation-utils';

interface IProps {
  customStyle?: string;
  image: string;
  gif?: string;
  id: number;
  // year: string;
  // category: string;
  // title: string;
  setIsImageLoaded?: any;
  isImageLoaded?: boolean;
}

function ImageCard(props: IProps): JSX.Element {
  const {
    customStyle,
    // year,
    setIsImageLoaded,
    // category,
    // title,
    image,
    gif,
    isImageLoaded,
    id,
  } = props;

  // const [firstYearNumber, setFirstYearNumber] = useState('' as string);
  // const [secondYearNumber, setSecondYearNumber] = useState('' as string);
  // const [lastYearNumber, setLastYearNumber] = useState('' as string);
  const cardImage = useRef<any>(null);
  // const cardYear = useRef<any>(null);
  // const cardInfo = useRef<any>(null);

  const classNames = ['card'];
  if (customStyle) classNames.push(customStyle);

  // useEffect(() => {
  //   setFirstYearNumber(year.charAt(0));
  //   setSecondYearNumber(year.charAt(1));
  //   setLastYearNumber(year.slice(-2));
  // }, [year]);

  useEffect(() => {
    if (isImageLoaded) {
      showTextAnimation([cardImage.current], 0.3 * id, 1000);
      // showTextAnimation([cardYear.current, cardInfo.current], 0.4 * id);
    }
  }, [isImageLoaded, id]);

  return (
    <>
      {gif ? (
        <video
          ref={cardImage}
          src={gif}
          autoPlay
          loop
          muted
          className="image-card__gif"
          width="100%"
        />
      ) : (
        <img
          ref={cardImage}
          className="image-card__image"
          onLoad={setIsImageLoaded}
          src={image}
          width={300}
          alt={image}
        />
      )}
      {/* <div className="image-card__image--content">
          <p ref={cardYear} className="image-card__year">
            ({firstYearNumber}
            <span className="image-card__year image-card__year--font">
              {secondYearNumber}
            </span>
            {lastYearNumber})
          </p>
          <OverflowWrapper height="fit-content">
            <div ref={cardInfo} className="image-card__image--info">
              <p className="image-card__image--text">{category}</p>
              <p className="image-card__image--text">{title}</p>
            </div>
          </OverflowWrapper>
        </div> */}
    </>
  );
}

export default ImageCard;
