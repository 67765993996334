import './Footer.scss';
import { useEffect, useRef } from 'react';
import {
  preventsFlashing,
  showTextAnimation,
} from '../../../utils/animation-utils';

function Footer(): JSX.Element {
  const footerTitle = useRef<any>(null);
  const footerSocial = useRef<any>(null);

  useEffect(() => {
    preventsFlashing('.footer');
    showTextAnimation([footerTitle.current, footerSocial.current]);
  }, []);

  return (
    <>
      <nav className="footer">
        <div ref={footerTitle} className="footer__container">
          <p className="footer__message">{`© Marta Mullor — ${new Date().getFullYear()}`}</p>
        </div>
        <div ref={footerSocial} className="footer__container">
          <a className="footer__message--link" href="/contact" rel="noreferrer">
            Contact
          </a>
          <a
            className="footer__message--link"
            href="https://github.com/martamullor"
            target="_blank"
            rel="noreferrer"
          >
            Github
          </a>
          <a
            className="footer__message--link"
            href="https://www.linkedin.com/in/martamullor/"
            target="_blank"
            rel="noreferrer"
          >
            Linkedin
          </a>
          <a
            className="footer__message--link"
            href="https://www.instagram.com/martamullor/"
            target="_blank"
            rel="noreferrer"
          >
            Instagram
          </a>
          <a
            className="footer__message--link"
            href="https://www.artstation.com/martamullor"
            target="_blank"
            rel="noreferrer"
          >
            Artstation
          </a>
          <a
            className="footer__message--link"
            href="mailto:marta.mullor.polo@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            Mail
          </a>
        </div>
      </nav>
    </>
  );
}

export default Footer;
