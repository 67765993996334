import VonzuPhoto from '../assets/web-covers/vonzu-cover.png';
import DinamigPhoto from '../assets/web-covers/dinamig-cover.png';
import InboundPhoto from '../assets/web-covers/inbound-cover.png';
import FontvellaPhoto from '../assets/web-covers/fontvella-cover.png';
import CtocPhoto from '../assets/web-covers/ctoc-cover.png';
import DanonePhoto from '../assets/web-covers/danone-cover.png';
import AyonowPhoto from '../assets/web-covers/ayonow-cover.png';
import ValyPhoto from '../assets/web-covers/valy-cover.png';
import SmartbrainPhoto from '../assets/web-covers/smartbrain-cover.png';
import DroyPhoto from '../assets/web-covers/droy-cover.png';
import SandPhoto from '../assets/web-covers/sand-cover.png';
import EpsilonPhoto from '../assets/web-covers/epsilon-cover.png';
import HolaLuzPhoto from '../assets/web-covers/holaluz-cover.png';
import FundacioPhoto from '../assets/web-covers/fundacio-cover.png';
import BlenderPhoto from '../assets/web-covers/blender-cover.png';
import ILovePDFPhoto from '../assets/web-covers/ilovepdf-cover.png';
import NextmolPhoto from '../assets/web-covers/nextmol-cover.png';
// import BrutalismPhoto from '../assets/web-covers/burtalism-cover.png';

export const playgroundCategories = {
  blender: '3D Illustration Blender',
  design: '3D Illustration Blender',
  creativeCoding: '3D Illustration Blender',
};

export const workProjects = [
  {
    cursiveTypo: 'ILovePDF',
    mainTypo: 'Tools for PDFs',
    year: '01.05.2022 - Current',
    yearOnly: '2024',
    cover: ILovePDFPhoto,
    link: 'https://www.ilovepdf.com/',
    stack: ['Blender', 'Three.js'],
    type: 'Software Dev',
  },
  {
    cursiveTypo: 'Blender',
    mainTypo: '3D Exploration',
    year: '01.05.2022 - Current',
    yearOnly: '2024',
    cover: BlenderPhoto,
    link: 'https://www.artstation.com/martamullor',
    stack: ['Blender', 'Three.js'],
    type: 'Blender',
  },
  {
    cursiveTypo: 'Nextmol',
    mainTypo: 'Molecule Builder',
    year: '01.05.2022 - Current',
    yearOnly: '2023',
    cover: NextmolPhoto,
    link: 'https://www.nextmol.com/',
    stack: ['Blender', 'Three.js'],
    type: 'Software Dev',
  },
  {
    cursiveTypo: 'HolaLuz',
    mainTypo: 'green enery',
    year: '05.09.2022 - Current',
    yearOnly: '2023',
    cover: HolaLuzPhoto,
    link: 'https://www.holaluz.com/',
    stack: ['Vue.js'],
    type: 'Software Dev',
  },
  {
    cursiveTypo: 'Vonzu',
    mainTypo: 'Tech.',
    year: '05.12.2020 - 05.09.2022',
    yearOnly: '2022',
    cover: VonzuPhoto,
    link: 'https://vonzu.io/',
    stack: ['React.js', 'Typescript'],
    type: 'Software Dev',
  },
  {
    cursiveTypo: 'Dinamig',
    mainTypo: 'Olot',
    year: '17.10.2022',
    yearOnly: '2022',
    cover: DinamigPhoto,
    link: 'https://dinamig.cat/',
    stack: ['Figma', 'Wordpress'],
    type: 'UX/UI Design',
  },
  {
    cursiveTypo: 'Inbound',
    mainTypo: 'Cycle',
    year: '13.03.2020 - 20.10.2022',
    yearOnly: '2022',
    cover: InboundPhoto,
    link: 'https://www.inboundcycle.com/',
    stack: ['InDesign', 'Wordpress'],
    type: 'UX/UI Design',
  },
  {
    cursiveTypo: 'CtoC',
    mainTypo: 'Call Center',
    year: '26.06.2021',
    yearOnly: '2022',
    cover: CtocPhoto,
    link: 'https://www.ctoc.es/',
    stack: ['Figma', 'Wordpress'],
    type: 'UX/UI Design',
  },
  {
    cursiveTypo: 'Ayonow',
    mainTypo: 'Digital Agency',
    year: '03.02.2021',
    cover: AyonowPhoto,
    yearOnly: '2021',
    link: 'https://www.ayonow.com/es/',
    stack: ['Figma', 'Wordpress'],
    type: 'UX/UI Design',
  },
  {
    cursiveTypo: 'Fundació',
    mainTypo: '.cat',
    year: '15.07.2020',
    yearOnly: '2021',
    cover: FundacioPhoto,
    link: 'https://domini.cat/',
    stack: ['React Native', 'Firebase'],
    type: 'Software Dev',
  },
  {
    cursiveTypo: 'FontVella',
    mainTypo: 'en casa',
    year: '24.06.2020',
    yearOnly: '2020',
    cover: FontvellaPhoto,
    link: 'https://www.fontvellaencasa.es/',
    stack: ['Ionic', 'Angular.js'],
    type: 'Software Dev',
  },
  {
    cursiveTypo: 'Danone',
    mainTypo: 'Danmatch',
    year: '17.07.2020',
    yearOnly: '2020',
    cover: DanonePhoto,
    link: 'https://www.danone.es/',
    stack: ['Angular.js'],
    type: 'Software Dev',
  },
  {
    cursiveTypo: 'SmartBrain',
    mainTypo: 'Hospital Games',
    year: '16.05.2020',
    yearOnly: '2020',
    cover: SmartbrainPhoto,
    link: 'https://www.smartbrain.es/',
    stack: ['Angular.js'],
    type: 'Software Dev',
  },
  {
    cursiveTypo: 'Droy',
    mainTypo: 'Website Builder',
    year: '24.06.2019',
    yearOnly: '2019',
    cover: DroyPhoto,
    stack: ['React.js', 'Express.js', 'MongoDB', 'node.js'],
    type: 'Software Dev',
  },
  {
    cursiveTypo: 'Sand',
    mainTypo: 'Beach Status',
    year: '10.03.2019',
    yearOnly: '2019',
    cover: SandPhoto,
    stack: ['Express.js', 'node.js'],
    type: 'Software Dev',
  },
  {
    cursiveTypo: 'Valy Cosmetics',
    mainTypo: 'Beauty',
    year: '10.02.2019',
    yearOnly: '2019',
    cover: ValyPhoto,
    stack: ['Figma', 'Wordpress'],
    type: 'Software Dev',
  },
  {
    cursiveTypo: 'Epsilon',
    mainTypo: 'Technologies',
    year: '10.07.2018',
    yearOnly: '2018',
    cover: EpsilonPhoto,
    link: 'https://www.epsilontec.com/casos-de-uso/',
    stack: ['Wordpress'],
    type: 'Data Analyst',
  },
];
