import './ProvisionalPage.scss';
import { gsap } from 'gsap';
import { useLayoutEffect, useRef } from 'react';
import {
  showTextAnimation,
  preventsFlashing,
} from '../../utils/animation-utils';
import { workProjects } from '../../utils/work-utils';
import { generateKey } from '../../utils/utils';
import { OverflowWrapper } from '../../components/Basic';
import useMediaQuery from '../../hooks/useMediaQuery';

function ProvisionalPage(): JSX.Element {
  const textTitle = useRef<any>(null);
  const revealRefs = useRef<HTMLDivElement[]>([]);
  revealRefs.current = [];
  const isPageWide = useMediaQuery('(min-width: 600px)');

  const listAnimation = () => {
    revealRefs.current.forEach((el, index) => {
      gsap.fromTo(
        el,
        {
          autoAlpha: 0,
          y: 40,
          delay: 1,
        },
        {
          autoAlpha: 1,
          scrollTrigger: {
            id: `section-${index + 1}`,
            trigger: el,
            start: isPageWide ? 'top 96%' : 'top 100%',
            toggleActions: 'play none none reverse',
          },
          duration: 1,
          ease: 'power3.out',
          y: 0,
          delay: 0.5,
        },
      );
    });
  };

  useLayoutEffect(() => {
    preventsFlashing('.provisional-page__content');
    showTextAnimation([textTitle.current], 0.2, 420);
  }, []);

  const addToRefs = (el: HTMLDivElement) => {
    listAnimation();
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    } else {
      revealRefs.current.push(el);
    }
  };

  /* const mouseOver = () => {
    const tl = gsap.timeline();

    tl.to('.provisional-page__project', {
      delay: 0.1,
      duration: 0.8,
      border: '1px solid red',
      ease: 'power4.out',
    });
  }; */

  return (
    <div className="provisional-page__content">
      <OverflowWrapper height="fit-content">
        <p ref={textTitle} className="provisional-page__text">
          Hey! I&apos;m Marta Mullor (SHE/HER) a Software Engineer and Digital
          Designer from Barcelona, Spain. Passionate about creating
          unforgettable and beautiful digital experiences. Don&apos;t be shy and
          let&apos;s collaborate and make something good together!
        </p>
      </OverflowWrapper>
      {workProjects.map((project: any) => {
        return (
          <div
            // onMouseEnter={() => mouseOver()}
            ref={addToRefs}
            className={generateKey(project.cursiveTypo)}
            key={generateKey(project.cursiveTypo)}
          >
            <div className="provisional-page__project">
              <div className="provisional-page__info">
                <p className="provisional-page__project--text">
                  {project.cursiveTypo}
                </p>
                {project.link && (
                  <a
                    className="provisional-page__project--link"
                    target="_blank"
                    href={project.link}
                    rel="noreferrer"
                  >
                    {project.stack.includes('Blender') ? (
                      <span className="provisional-page__project--link">
                        Project &#8599;
                      </span>
                    ) : (
                      <span className="provisional-page__project--link">
                        Live site &#8599;
                      </span>
                    )}
                  </a>
                )}
              </div>
              <div className="provisional-page__project--stack">
                {project.stack.map((stack: any) => {
                  return <p key={generateKey(stack)}>{stack}</p>;
                })}
              </div>
              <div>
                <p className="provisional-page__project--year">
                  {project.year}
                </p>
              </div>
            </div>
            <hr className="provisional-page__project--line" />
          </div>
        );
      })}
    </div>
  );
}

export default ProvisionalPage;
