import './YearText.scss';

import { useEffect, useState, useRef } from 'react';
import OverflowWrapper from '../OverflowWrapper/OverflowWrapper';
import { showTextAnimation } from '../../../utils/animation-utils';

interface IProps {
  year: string;
  text?: string;
  overflowWidth?: string;
}

function YearText(props: IProps): JSX.Element {
  const { year, text, overflowWidth } = props;
  const [firstYearNumber, setFirstYearNumber] = useState('' as string);
  const [secondYearNumber, setSecondYearNumber] = useState('' as string);
  const [lastYearNumber, setLastYearNumber] = useState('' as string);
  const ref = useRef<any>(null);
  const textRef = useRef<any>(null);

  useEffect(() => {
    setFirstYearNumber(year.charAt(0));
    setSecondYearNumber(year.charAt(1));
    setLastYearNumber(year.slice(-2));
    showTextAnimation([ref.current, textRef.current], 0.2, 220);
  }, [year]);

  return (
    <OverflowWrapper width={overflowWidth} height="fit-content">
      <p ref={ref} className="year-text">
        {firstYearNumber}
        <span className="year-text year-text--font">{secondYearNumber}</span>
        {lastYearNumber}
      </p>
      {text && (
        <p ref={textRef} className="year-text__info">
          {text}
        </p>
      )}
    </OverflowWrapper>
  );
}

export default YearText;
