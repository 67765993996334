/* eslint-disable import/prefer-default-export */
import Photo4 from '../assets/photos/IMG_5969.jpg';
import Photo5 from '../assets/photos/IMG_5970.jpg';
import Photo6 from '../assets/photos/IMG_5983.jpg';
import Photo9 from '../assets/photos/IMG_6090.jpg';
import Photo10 from '../assets/photos/IMG_6092.jpg';
import Photo11 from '../assets/photos/IMG_6347.jpg';
import Photo12 from '../assets/photos/IMG_6441.jpg';
import Photo13 from '../assets/photos/IMG_6452.jpg';
import Photo15 from '../assets/photos/IMG_6514.jpg';

export const photos = [
  {
    photo: Photo12,
  },
  {
    photo: Photo15,
  },
  {
    photo: Photo13,
  },
  {
    photo: Photo11,
  },
  {
    photo: Photo4,
  },
  {
    photo: Photo5,
  },
  {
    photo: Photo6,
  },
  {
    photo: Photo9,
  },
  {
    photo: Photo10,
  },
];
