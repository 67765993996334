import './NotFoundPage.scss';

import { useEffect, useRef } from 'react';
import {
  showTextAnimation,
  preventsFlashing,
} from '../../utils/animation-utils';
import { OverflowWrapper } from '../../components/Basic';

interface IProps {
  titleMessage?: string;
  pageMessage?: string;
}

function NotFoundPage(props: IProps): JSX.Element {
  const { titleMessage, pageMessage } = props;

  const refTitle = useRef<any>(null);
  const refText = useRef<any>(null);

  useEffect(() => {
    preventsFlashing('.project-page__info');
    showTextAnimation([refTitle.current], 0.3);
    showTextAnimation([refText.current], 0.5);
  }, []);
  return (
    <div className="not-found-page">
      <OverflowWrapper height="fit-content">
        <p ref={refTitle} className="not-found-page__title">
          {titleMessage || 'Error 404'}
        </p>
      </OverflowWrapper>
      <OverflowWrapper height="fit-content">
        <p ref={refText} className="not-found-page__text">
          {pageMessage ||
            'Unfortunately, page you are looking for is not found. Please, try use another route.'}
        </p>
      </OverflowWrapper>
    </div>
  );
}

export default NotFoundPage;
