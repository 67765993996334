export const replaceJsonSpaces = (str: string): string => {
  return str.replace(/\n/g, '<br />');
};

export const loadImage = (image: string): any => {
  return new Promise((resolve, reject) => {
    const loadImg = new Image();
    loadImg.src = image;
    loadImg.onload = () =>
      setTimeout(() => {
        resolve(image);
      }, 500);

    loadImg.onerror = (err) => reject(err);
  });
};
export const areImagesLoaded = (
  images: string[],
  setLoader: React.Dispatch<React.SetStateAction<boolean>>,
): void => {
  Promise.all(images.map((image) => loadImage(image))).then(() =>
    setLoader(true),
  );
};

export const generateKey = (pre: string): string => {
  return `${pre}_${new Date().getTime()}`;
};
