import './WorkPage.scss';
import {
  useState,
  useRef,
  createRef,
  useContext,
  useLayoutEffect,
} from 'react';
import { gsap } from 'gsap';
import { OverflowWrapper, YearText, Photo } from '../../components/Basic';
import { Menu } from '../../components/Complex';
import {
  showTextAnimation,
  preventsFlashing,
  handleRemovePage,
  handleTransformToGrayscale,
  handleRevertTransformToGrayscale,
} from '../../utils/animation-utils';
import { workProjects } from '../../utils/work-utils';
import { areImagesLoaded } from '../../utils/utils';
import { workProjectsTypes } from '../page-types';
import { MouseContext } from '../../Context/MouseContext';

function WorkPage(): JSX.Element {
  const workTitle = useRef<any>(null);
  const workCurated = useRef<any>(null);
  const workDrag = useRef<any>(null);
  const [title, setTitle] = useState('My' as string);
  const [subtitle, setSubtitle] = useState('Work' as string);
  const [isTransitionActive, setIsTransitionActive] = useState(
    false as boolean,
  );
  const [projectsOrdered, setProjectsOrdered] = useState(
    [] as workProjectsTypes[],
  );
  const [imgsLoaded, setImgsLoaded] = useState(false);
  const myRefs = useRef([]);
  const { cursorChangeHandler } = useContext(MouseContext);
  myRefs.current = projectsOrdered.map(
    (x, i: number) => myRefs.current[i] ?? createRef(),
  );

  useLayoutEffect(() => {
    if (imgsLoaded) {
      cursorChangeHandler('');
    } else {
      cursorChangeHandler('loading', 'Loading');
      areImagesLoaded(
        workProjects.map((elem) => elem.cover),
        setImgsLoaded,
      );

      const result = [] as any;
      workProjects.forEach((project) => {
        if (!result.includes(project.yearOnly)) {
          result.push(project.yearOnly);
        }
        result.push(project);
      });
      setProjectsOrdered(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgsLoaded]);

  const handlePlay = (currentTitle: string, currentSubtitle: string) => {
    const tl = gsap.timeline();
    tl.to('.work-page__title', {
      duration: 1.3,
      y: -150,
      ease: 'power3.out',
      opacity: 0,
    });
    setTimeout(() => {
      setTitle(currentTitle);
      setSubtitle(currentSubtitle);
    }, 850);
    tl.to('.work-page__title', {
      y: '160',
      delay: -0.3,
    }).to('.work-page__title', {
      duration: 0.85,
      y: 0,
      ease: 'power3.out',
      opacity: 1,
    });
  };

  const onMouseEnterHandler = (
    currentTitle: string,
    currentSubtitle: string,
    projectType: string,
  ) => {
    setIsTransitionActive(true);
    handlePlay(currentTitle, currentSubtitle);
    setTimeout(() => {
      cursorChangeHandler('cursor', projectType);
    }, 200);
  };

  const onMouseLeaveHandler = () => {
    cursorChangeHandler('');
    setIsTransitionActive(false);
    handlePlay('My', 'Work');
  };

  useLayoutEffect(() => {
    if (imgsLoaded) {
      preventsFlashing('.work-page__content');
      showTextAnimation(
        [workTitle.current, workCurated.current, workDrag.current],
        0.3,
        220,
      );
      myRefs.current.map((x: any) => {
        return showTextAnimation([x.current], 0.5, 500);
      });
    }
  }, [imgsLoaded]);

  const handlePhotoStyle = (activeProject: string) => {
    if (isTransitionActive && activeProject === title) {
      handleTransformToGrayscale('.work-page__photo');
      return 'work-page__photo';
    }
    if (isTransitionActive && activeProject !== title) {
      handleRevertTransformToGrayscale('.work-page__photo--revert');
      return 'work-page__photo--revert';
    }
    if (!isTransitionActive) {
      handleTransformToGrayscale('.work-page__photo');
      return 'work-page__photo';
    }
    return '';
  };

  return (
    <>
      <div className="work-page__content">
        <OverflowWrapper marginTop="120px" height="fit-content">
          <div className="work-page__title--content">
            <p
              ref={workCurated}
              className="work-page__title--subtitle"
              onMouseEnter={() => cursorChangeHandler('onHoverText')}
              onMouseLeave={() => cursorChangeHandler('')}
            >
              Curated <br /> since 2019
            </p>
            <OverflowWrapper width="fit-content" height="177px">
              <p
                onMouseEnter={() => cursorChangeHandler('')}
                onMouseLeave={() => cursorChangeHandler('')}
                ref={workTitle}
                className="work-page__title"
              >
                <span className="work-page__title--font">{title} </span>
                {subtitle}
              </p>
            </OverflowWrapper>
            <p
              ref={workDrag}
              className="work-page__title--subtitle"
              onMouseEnter={() => cursorChangeHandler('onHoverText')}
              onMouseLeave={() => cursorChangeHandler('')}
            >
              Hold and drag <br /> to discover
            </p>
          </div>
        </OverflowWrapper>
        {imgsLoaded && (
          <Menu
            handleRemovePage={() =>
              handleRemovePage(
                [
                  '.work-page__title--content',
                  '.work-page__projects',
                  '.work-page__title--subtitle',
                ],
                0.1,
                0.1,
              )
            }
          />
        )}
        <OverflowWrapper height="fit-content">
          <div className="work-page__projects">
            <div className="work-page__projects--container">
              {projectsOrdered.map((project, i: number) => {
                return (
                  <div
                    className={
                      typeof project === 'string'
                        ? 'work-page__projects--year'
                        : 'work-page__projects--content'
                    }
                    key={`project_${
                      typeof project === 'string' ? project : project.mainTypo
                    }`}
                  >
                    {typeof project === 'string' && (
                      <>
                        {imgsLoaded && (
                          <span
                            onMouseEnter={() => cursorChangeHandler('')}
                            onMouseLeave={() => cursorChangeHandler('')}
                          >
                            <YearText
                              year={project}
                              text={`${
                                projectsOrdered.filter(
                                  (pr: any) => pr.yearOnly === project,
                                ).length
                              } projects`}
                            />
                          </span>
                        )}
                      </>
                    )}
                    <a
                      ref={myRefs.current[i]}
                      className="work-page__projects--image"
                      href={project.link}
                      target="_blank"
                      rel="noreferrer"
                      onKeyDown={() => cursorChangeHandler('')}
                      onMouseLeave={() => onMouseLeaveHandler()}
                      onMouseEnter={() =>
                        onMouseEnterHandler(
                          project.cursiveTypo,
                          project.mainTypo,
                          project.type,
                        )
                      }
                    >
                      <Photo
                        customStyle={handlePhotoStyle(project.cursiveTypo)}
                        src={project.cover}
                        alt={project.cursiveTypo}
                      />
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </OverflowWrapper>
      </div>
    </>
  );
}

export default WorkPage;
