import './ContactPage.scss';
import { useEffect, useRef } from 'react';
import { OverflowWrapper, BlockText } from '../../components/Basic';
import { Menu } from '../../components/Complex';
import {
  showTextAnimation,
  preventsFlashing,
  handleRemovePage,
} from '../../utils/animation-utils';

function ContactPage(): JSX.Element {
  const contactTitle = useRef<any>(null);
  const contactTitleColab = useRef<any>(null);

  useEffect(() => {
    preventsFlashing('.contact-page__content');
    showTextAnimation(
      [contactTitle.current, contactTitleColab.current],
      0.4,
      220,
    );
    showTextAnimation(
      ['.block-text__info--title', '.block-text__info--text'],
      0.4,
      220,
    );
  }, []);

  return (
    <div className="contact-page__content">
      <div className="contact-page__text">
        <OverflowWrapper height="fit-content">
          <div className="contact-page__title--content">
            <p ref={contactTitle} className="contact-page__title">
              <span className="contact-page__title contact-page__title--font">
                Let&apos;s collaborate{' '}
              </span>
              <br />
            </p>
          </div>
        </OverflowWrapper>
        <OverflowWrapper height="fit-content">
          <div className="contact-page__title--content">
            <p ref={contactTitleColab} className="contact-page__title">
              and make something good together
            </p>
          </div>
        </OverflowWrapper>
      </div>
      <Menu
        handleRemovePage={() =>
          handleRemovePage(
            ['.contact-page__title--content', '.contact-page__info'],
            0.3,
            0.1,
          )
        }
      />
      <OverflowWrapper height="fit-content">
        <div className="contact-page__info">
          <BlockText
            title="Social"
            options={[
              {
                url: 'https://www.instagram.com/martamullor/',
                text: 'Instagram',
              },
              {
                url: 'https://www.linkedin.com/in/martamullor/',
                text: 'Linkedin',
              },
            ]}
          />
          <BlockText
            title="Porfolio"
            options={[
              { url: 'https://dribbble.com/martamullor', text: 'Dribble' },
              {
                url: 'https://www.artstation.com/martamullor',
                text: 'ArtStation',
              },
            ]}
          />
          <BlockText
            title="Mail"
            options={[
              {
                url: 'mailto:marta.mullor.polo@gmail.com',
                text: 'marta.mullor.polo@gmail.com',
              },
            ]}
          />
        </div>
      </OverflowWrapper>
    </div>
  );
}

export default ContactPage;
