/* eslint-disable @typescript-eslint/no-empty-function */
import './Menu.scss';
import { useEffect, useState, useContext, useRef } from 'react';
import { gsap } from 'gsap';
import { useHistory, useLocation } from 'react-router-dom';
import { OverflowWrapper, Button } from '../../Basic';
import { showTextAnimation } from '../../../utils/animation-utils';
import { MouseContext } from '../../../Context/MouseContext';

interface IProps {
  handleRemovePage: () => void;
  position?: 'static' | 'relative' | 'absolute' | 'sticky' | 'fixed';
  zIndex?: number;
  type?: 'top';
}

function Menu(props: IProps): JSX.Element {
  const { handleRemovePage, position, zIndex, type } = props;
  const menuTitle = useRef<any>(null);
  const backgroundTitle = useRef<any>(null);
  const menuRef = useRef<any>(null);
  const textRef = useRef<any>(null);
  const titleRef = useRef<any>(null);
  const [title, setTitle] = useState('' as string);
  const [isATransitionPage, setIsATransitionPage] = useState(true);
  const [overflow, setOverflow] = useState('hidden' as 'hidden' | 'visible');
  const history = useHistory();
  const location = useLocation();
  const { cursorChangeHandler } = useContext(MouseContext);

  useEffect(() => {
    showTextAnimation([menuTitle.current, backgroundTitle.current], 0.2, 200);
  }, []);

  useEffect(() => {
    const tl = gsap.timeline();
    menuRef.current = gsap.to(backgroundTitle.current, {
      duration: 0.8,
      height: '110px',
      paused: true,
      ease: 'expo.inOut',
    });
    textRef.current = gsap.to('.menu__content--menu--text', {
      duration: 0.6,
      color: '#ebebeb',
      paused: true,
    });
    titleRef.current = tl
      .to('.menu__content--background--title', {
        duration: 0.3,
        opacity: 0.04,
      })
      .to('.menu__content--background--title', {
        duration: 0.8,
        ease: 'power3.out',
        delay: 0.8,
        marginTop: '0px',
      });
  }, []);

  const onMouseEnterHandler = (currentTitle: string) => {
    if (isATransitionPage) {
      cursorChangeHandler('hovered');
      setTitle(currentTitle);
      menuRef.current.play();
      textRef.current.play();
      titleRef.current.play();
    }
  };
  const onMouseLeaveHandler = () => {
    if (isATransitionPage) {
      cursorChangeHandler('');
      menuRef.current.reverse();
      textRef.current.reverse();
      titleRef.current.reverse();
    }
  };

  const handleChangePage = (link: string) => {
    const tl = gsap.timeline();
    setIsATransitionPage(false);
    setOverflow('visible');

    handleRemovePage();

    tl.to('.menu__content--menu--text', {
      opacity: 0,
      duration: 0.3,
    })
      .to(backgroundTitle.current, {
        duration: 0.7,
        height: '130vh',
        ease: 'power1.inOut',
      })
      .to('.menu__content--background--title', {
        delay: -0.2,
        duration: 0.7,
        opacity: 1,
      })
      .to(backgroundTitle.current, {
        duration: 0.8,
        delay: -0.5,
        marginTop: '-250vh',
        ease: 'power3.easeOut',
      });
    cursorChangeHandler('loading');
    setTimeout(() => {
      history.push(link);
    }, 1900);
  };

  return (
    <OverflowWrapper
      position={position}
      overflow={overflow}
      zIndex={zIndex}
      height="fit-content"
      customStyle={type === 'top' ? 'menu__content--top' : ''}
    >
      <div ref={menuTitle} className="menu__content--menu">
        <Button
          customStyle="menu__content--menu--text"
          onMouseEnter={() => onMouseEnterHandler('Work')}
          onMouseLeave={onMouseLeaveHandler}
          onClick={
            location.pathname !== '/work'
              ? () => handleChangePage('/work')
              : () => {}
          }
          number="01."
          text="Work"
        />
        <Button
          customStyle="menu__content--menu--text"
          onMouseEnter={() => onMouseEnterHandler('Playground')}
          onMouseLeave={onMouseLeaveHandler}
          onClick={
            location.pathname !== '/playground'
              ? () => handleChangePage('/playground')
              : () => {}
          }
          number="02."
          text="Playground"
        />
        <Button
          customStyle="menu__content--menu--text"
          onMouseEnter={() => onMouseEnterHandler('Photos')}
          onMouseLeave={onMouseLeaveHandler}
          onClick={
            location.pathname !== '/photos'
              ? () => handleChangePage('/photos')
              : () => {}
          }
          number="03."
          text="Photos"
        />
        <p className="menu__content--menu--text">{`©${new Date().getFullYear()}`}</p>
        <div className="menu__content--background" ref={backgroundTitle}>
          <p className="menu__content--background--title">{title}</p>
        </div>
      </div>
    </OverflowWrapper>
  );
}

export default Menu;
